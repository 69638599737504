@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");

html {
  font-family: "Work Sans", sans-serif !important;
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

input:focus {
  outline: none !important;
}

nav {
  flex-grow: 1;
}

body {
  margin: 0;
  letter-spacing: 0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  position: relative;
  padding-top: 4.3rem;
}

/* Map */
.leaflet-container {
  height: 100%;
  background: #e4e4e4;
  position: relative;
}

.leaflet-control-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3.5rem;
  height: 3.5rem;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-left .leaflet-control {
  margin: 0 !important;
}

div.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-touch .leaflet-bar a:first-child {
  width: 3.5rem;
  border-radius: 3.5rem !important;
  height: 3.5rem;
  margin-bottom: 0.5rem;
}

.leaflet-touch .leaflet-bar a:last-child {
  width: 3.5rem;
  border-radius: 3.5rem !important;
  height: 3.5rem;
}

div a.leaflet-control-zoom-in,
div a.leaflet-control-zoom-out {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

div a.leaflet-control-zoom-in {
  background-image: url("assets/images/map/plus.svg");
}

div a.leaflet-control-zoom-out {
  background-image: url("assets/images/map/minus.svg");
}

.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span {
  color: transparent;
}

.leaflet-bar a.leaflet-disabled {
  display: none;
}

/* Scrollbar */
.scrollable::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.scrollable::-webkit-scrollbar-track {
  border-radius: 5.875rem;
  background: #f7d8af;
  margin: 1rem;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #f1b267;
  border: 0.18rem solid #f7d8af;
  border-radius: 5.875rem;
}

.scrollableHero::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.scrollableHero::-webkit-scrollbar-track {
  border-radius: 5.875rem;
  background: #e4e4e4;
  margin: 1rem;
}

.scrollableHero::-webkit-scrollbar-thumb {
  background-color: #f9d797;
  border: 0.18rem solid #e4e4e4;
  border-radius: 5.875rem;
}

/* Info Popup */
.tooltip {
  position: relative;
  display: inline-block;
}

.chart-tooltip-color {
  width: 0.5rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  height: 0.5rem;
  border: 1px solid white;
  margin-right: 0.5rem;
}
.tooltip .tooltip-text {
  padding: 16px;
  border-radius: 8px;
  background-color: #5c5c5c;
  visibility: hidden;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  z-index: 402;
}

.tooltip .tooltip-text:hover {
  box-shadow: 0px 1px 4px 0px rgba(37, 37, 37, 0.3);
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.mobile-hide-map {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

/* Meida queries */
@media (min-width: 1200px) and (min-height: 800px) {
  #home {
    height: auto;
  }

  #home > article:first-of-type {
    padding: 2.5rem 4rem;
  }
}

@media (max-width: 1200px) {
  #home > video,
  #home > img {
    display: none;
  }
}

@media (max-width: 768px) {
  #home > article {
    background: linear-gradient(
      180deg,
      rgba(255, 202, 114, 1) 0%,
      rgba(247, 247, 247, 1) 6%,
      rgba(255, 255, 255, 1) 28%,
      rgba(247, 247, 247, 1) 35%,
      rgba(255, 202, 114, 1) 100%
    );
  }

  main {
    padding-top: 3.8rem;
  }

  input:not(".inputCountrySearchMobile") {
    pointer-events: none !important;
  }
}

@media (max-width: 639px) {
  .indicators {
    bottom: 6rem !important;
  }
}

/* Ethiopia Modal */
@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutToBottom {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.animate-slide-in {
  animation: slideInFromBottom 0.3s ease-out forwards;
}

.animate-slide-out {
  animation: slideOutToBottom 0.3s ease-in forwards;
}
